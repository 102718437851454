import { useSearchParams } from 'react-router-dom'

import type { InsuranceType } from '@/gql/graphql'

export interface InitialData {
  policyType: InsuranceType
  firstName: string
  lastName: string
  email: string
  agentId?: string
}

const LOCAL_STORAGE_KEY = 'initialApplicationData'

function fromLocalStorage(): InitialData | null {
  const data = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (data != null && data !== '') {
    return JSON.parse(data) as InitialData
  }
  return null
}

function fromSearchParams(searchParams: URLSearchParams): InitialData | null {
  const policyType = searchParams.get('insuranceType') as InsuranceType | null
  const firstName = searchParams.get('firstName')
  const lastName = searchParams.get('lastName')
  const email = searchParams.get('email')
  const agentId = searchParams.get('agentId')

  if (
    policyType != null &&
    firstName != null &&
    lastName != null &&
    email != null
  ) {
    return {
      policyType,
      firstName,
      lastName,
      email,
      agentId: agentId ?? undefined,
    }
  }

  return null
}

function writeToLocalStorage(data: InitialData) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
}

function clearInitialData() {
  localStorage.removeItem(LOCAL_STORAGE_KEY)
}

export function useInitialDataFromSearchParams() {
  const [searchParams] = useSearchParams()
  const initialData = fromSearchParams(searchParams)
  if (initialData != null) {
    writeToLocalStorage(initialData)
  } else {
    clearInitialData()
  }

  return initialData
}

export function useInitialDataFromLocalStorage() {
  const initialData = fromLocalStorage()
  return [initialData, clearInitialData] as const
}
